
// display:none ------------------------------

#nav,
#sidebar,
.diver-sns-btns,
#pickup_posts_container,
#commentform,
.navigation-post,
#cta,
.navigation-post,
.sns-list-box,
.wrap-post-box,
.main-title,
.widget,
#footer,
#footer_cta,
#footer-cta-open,
#footer_sticky_menu,
#page-top,
.header-message,
#breadcrumb,
#onlynav,
#scrollnav,
#header-fix,
.nav_inleft,
.drawer-btn,
.header-search,
.header-btn
{
    display:none !important;
}

// header ------------------------------

.header-main{
    #logo{
        justify-content: center;
    }
    &__inner{
        justify-content: center;
    }
}

.header-mini{
    &__inner{
        justify-content: center;
    }
    .header_small_menu_right{
        display: none;
    }
}

// article ------------------------------

.single-article-container{
    &__main{
        padding:0;
    }
    footer.article-footer,
    &__side{
        display: none;
    }
}

// author ------------------------------

.post_footer_author{
    .post_footer_author_title_post,
    .author-posts{
        display: none;
    }
    
}

// ad -------------------------------

.diver_widget_adlabel,.adsbygoogle{
    display: none;
}